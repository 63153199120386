import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import Logo from '../logos/white_logo.png';

const Footer = () => {

    return <div className="footer mt-16 md:mt-32 text-base">
        <div className='container'>
            {/* <div className="hidden w-full md:flex justify-between items-center">
                <div className="w-1/4 flex gap-2">
                    <FontAwesomeIcon icon={faFacebook} />
                    <FontAwesomeIcon icon={faTwitter} />
                    <FontAwesomeIcon icon={faYoutube} />
                    <FontAwesomeIcon icon={faInstagram} />
                </div>
                <img alt='Logo' className="w-1/2" src={Logo} />
                <div className="w-1/4 flex justify-end">
                    <span>2023 Copyrights & Protected</span>
                </div>
            </div> */}
            <div className="flex w-full justify-between items-center">
                <img alt='Logo' className="w-1/4" src={Logo} />
                <div className='block'>
                    <div className="flex gap-2">
                        <FontAwesomeIcon icon={faFacebook} />
                        <FontAwesomeIcon icon={faTwitter} />
                        <FontAwesomeIcon icon={faYoutube} />
                        <FontAwesomeIcon icon={faInstagram} />
                    </div>
                    <div className="text-xs mt-4 text-gray-300 flex justify-end">
                        <span>2024 Copyrights & Protected</span>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default Footer;