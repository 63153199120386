import { React, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';
import emailjs from '@emailjs/browser';

const Contact = () => {

    const [templateParams, setTemplateParams] = useState({
        from_name: "",
        email: "",
        message: "",
        phone: "",
        enquiry_for: ""
    });

    // djavhisekh448@gmail.com

    const sendEmail = (e) => {
        e.preventDefault()
        console.log("templateParams", templateParams)
        // emailjs.send('service_h3980ld', 'template_2mta24n', templateParams, 'ky31gQtIttddazFI0')
        //     .then((response) => {
        //         console.log('SUCCESS!', response.status, response.text);
        //     }, (err) => {
        //         console.log('FAILED...', err);
        //     });
    };

    const handleChange = (event) => {
        setTemplateParams({
            ...templateParams,
            [event.target.name]: event.target.value
        })
    }

    // const handleDropDown = (event) => {
    //     console.log("DropDown", event.target.value)
    // }
    const drop_down = [
        "Photography",
        "Cinematography",
        "On Location Sound",
        "Music Production",
        "Graphic Designing",
        "Record Production"
    ];

    return <div className="mt-10 flex justify-center" id="contact">
        <div className='container'>
            <div className='w-full Header'>
                <div className='w-full font-extrabold text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-center'>
                    <span>
                        Let's Work Together<span className='avi_color'>.</span>
                    </span>
                </div>
                <div className='w-full mt-5 text-sm md:text-base lg:text-lg text-center'>
                    <span>
                        "Crafting Visual Tapestry. A Multidimensional Journey of Flimmaking, Photography, Music & Graphic Design."
                    </span>
                </div>

                <div className="md:flex mt-20">
                    <div className="my-info">
                        <div className="flex items-center gap-10 md:gap-6">
                            <span className='w-6 md:w-9 lg:w-12'>
                                <FontAwesomeIcon className='avi_color' icon={faPhone} size='2x' />
                            </span>
                            <div className='grid'>
                                <span className="text-sm lg:text-base">
                                    Call Me
                                </span>
                                <span className="text-base lg:text-xl font-bold">
                                    +977 9803874287
                                </span>
                            </div>
                        </div>
                        <div className="flex items-center gap-10 md:gap-6 mt-10">
                            <span className='w-6 md:w-9 lg:w-12'>
                                <FontAwesomeIcon className='avi_color' icon={faEnvelope} size='2x' />
                            </span>
                            <div className='grid'>
                                <span className="text-sm lg:text-base">
                                    Email Me
                                </span>
                                <span className="text-base lg:text-xl font-bold">
                                    djavhishek448@gmail.com
                                </span>
                            </div>
                        </div>
                        <div className="flex items-center gap-10 md:gap-6 mt-10">
                            <span className='w-6 md:w-9 lg:w-12'>
                                <FontAwesomeIcon className='avi_color' icon={faLocationDot} size='2x' />
                            </span>
                            <div className='grid'>
                                <span className="text-sm lg:text-base">
                                    My Address
                                </span>
                                <span className="text-base lg:text-xl font-bold">
                                    Kathmandu, Nepal
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="mt-16 md:mt-0 md:ml-10 text-sm lg:text-base lg:ml-24 form w-full">
                        <form method='post' onSubmit={(event)=>sendEmail(event)}>
                            <div className='md:flex md:gap-4 lg:gap-10'>
                                <div className='w-full md:w-1/2'>
                                    <label className='ml-4 text-sm font-semibold'> Your Name</label>
                                    <input className='avi_input mt-2 w-full px-2' placeholder='Enter Your Name Here' name='from_name' value={templateParams?.from_name} onChange={handleChange} required={true}></input>
                                </div>

                                <div className='w-full mt-8 md:mt-0 md:w-1/2'>
                                    <label className='ml-4 text-sm font-semibold'> How can i help you?</label>
                                    <select className='avi_input mt-2 w-full' name='enquiry_for' onChange={handleChange} value={templateParams?.enquiry_for} required>
                                        <optgroup className='mt-12'>
                                            <option key={0} value="">
                                                Select ...
                                            </option>
                                            {
                                                drop_down.map((value, index) => <option key={index + 1} >
                                                    {value}
                                                </option>)
                                            }
                                        </optgroup>
                                    </select>
                                </div>
                            </div>
                            <div className='md:flex md:mt-10 md:gap-4 lg:gap-10'>
                                <div className='w-full mt-8 md:mt-0 md:w-1/2'>
                                    <label className='ml-4 text-sm font-semibold'> Your Email</label>
                                    <input className='avi_input w-full mt-2 px-2' placeholder='Enter your email address' name='email' type='email' value={templateParams?.email} onChange={handleChange} required></input>
                                </div>
                                <div className='w-full mt-8 md:mt-0 md:w-1/2'>
                                    <label className='ml-4 text-sm font-semibold'> Your Phone No.</label>
                                    <input className='avi_input w-full mt-2 px-2' placeholder='Enter Your phone no.' name='phone' value={templateParams?.phone} onChange={handleChange} required></input>
                                </div>
                            </div>
                            <div className='md:flex mt-8 md:mt-10'>
                                <div className='w-full'>
                                    <label className='ml-4 text-sm font-semibold'>Your Message</label>
                                    <textarea className='avi_input w-full h-24 mt-2 px-2' placeholder='Your Message Here.' name='message' value={templateParams?.message} onChange={handleChange} required></textarea>
                                </div>
                            </div>
                            <div className='flex md:justify-end'>
                                <button className='self-end mt-5 py-3 font-bold avi_bg_color w-full md:w-40 rounded text-center' type='submit'>
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default Contact;