import HeroImage from '../imgs/AV Cut.png';
import { Link } from 'react-scroll';

const HeroSection = () => {
    const info_section = <div className='info-section py-10 md:py-0 w-full md:w-2/3'>
        <div>
            <div>
                <span className='font-extrabold text-3xl md:text-4xl lg:text-5xl xl:text-7xl'>
                    Hi, I'm Avhisekh Giri
                </span>
                <p className='mt-2 md:mt-3 lg:mt-4 text-sm lg:text-lg'>
                    Filmmaker, Photographer, Graphics Designer, Audio & Record Producer
                </p>
            </div>
            {/* <div className='mt-4 md:mt-8 lg:mt-12 flex gap-5'>
                <button className='w-1/2 md:w-40 button capitalize p-2 px-5 rounded-md hover:bg-[#1E212A] hover:font-bold hover:pt-1 transition duration-300 ease-in-out'>
                    <Link activeClass="active"
                        to="contact"
                        spy={true}
                        smooth={true}
                        duration={500}>
                        Get in touch
                    </Link>
                </button>
                <button className='w-1/2 md:w-40 p-2 px-5 rounded-md button-2 hover:font-bold hover:pt-1 hover:bg-[#1E212A] transition duration-300 ease-in-out'>
                    CV
                </button>
            </div> */}
        </div>
    </div>;

    const image_section = <div className='image-section mb-3 md:mb-0 w-fit xl:w1/3'>
        <img src={HeroImage} />
    </div>;
    // const image_section = <div className='mb-3'>
    //     <img src={HeroImage} />
    // </div>;

    return <>
        <div className="hidden herosection px-5 md:px-10 md:flex" id="herosection">
            {info_section}
            {image_section}
        </div>
        <div className="herosection px-5 md:px-10 md:hidden" id="herosection">
            {image_section}
            {info_section}
        </div>
        {/* <div className='grid grid-cols-2 gap-10'>
            {info_section}
            {image_section}
        </div> */}
        {/* <div className='line'>

        </div> */}
    </>;
};

export default HeroSection;