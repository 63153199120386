import Contact from "./components/contact";
import Footer from "./components/footer";
import HeroSection from "./components/heroSection";
import Navbar from "./components/navbar";

const Layout = () => {
    return <div>
        <Navbar />
        <HeroSection /> 
        <Contact />
        <Footer />
    </div>
}

export default Layout